



























































import { Component, Vue } from 'vue-property-decorator'
import { OfferModel } from '../models/OfferModel'
import breakpoints from '@/plugins/breakpoints'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import LandingTitle from './LandingTitle.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'

@Component({
  name: 'LandingOffer',
  components: { LandingTitle, Swiper, SwiperSlide, BaseIcon },
})
export default class LandingOffer extends Vue {
  breakpoints = breakpoints
  activePoint = 1
  activeObj: OfferModel = new OfferModel()

  swiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 32,
    centeredSlides: true,
    loop: true,
    pagination: {
      el: '.LandingOffer__pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.LandingOffer__next',
      prevEl: '.LandingOffer__prev',
    },
    // breakpoints: {
    //   480: {
    //     slidesPerView: 'auto',
    //     spaceBetween: 32,
    //   },
    //   320: {
    //     slidesPerView: 'auto',
    //     spaceBetween: 60,
    //   },
    // },
  }

  list = [
    {
      id: 1,
      title: 'Бесплатное размещение',
      text:
        'Для всех площадок, кроме кабинетов психолога и новых залов в разделе “Спорт”, до 15.04.2022 года!\n' +
        'Стоимость размещения кабинетов психолога зависит от количества локаций (адресов) и не зависит от количества кабинетов (от 990 руб за 2 месяца за адрес)\n' +
        'Размещение в разделе “Спорт” (спортивные площадки и залы) единоразовый платеж до 31.12.2022 - 1 990 руб. Без комиссий!\n' +
        'Узнать подробнее можно, связавшись с нами любым удобным способом.\n',
      image: '/img/landing/notebook/notebook.png',
      imageMobile: '/img/landing/notebook/notebook-mobile.png',
    },
    {
      id: 2,
      title: 'Полное отсутствие каких-либо комиссий',
      text:
        'Клиент связывается напрямую с арендодателем по подменному номеру (защита от спама вашего номера телефона). ' +
        'Или оставляет заявку, которая сразу приходит на электронную почту и телеграм.',
      image: '/img/landing/notebook/notebook.png',
      imageMobile: '/img/landing/notebook/notebook-mobile.png',
    },
    {
      id: 3,
      title: 'Удобный личный кабинет',
      text:
        'В нем доступна наглядная аналитика и статистика (кол-во просмотров, заявок, звонков от клиентов). ' +
        'Интеграция расписания с Google календарь, если это необходимо.',
      image: '/img/landing/notebook/notebook.png',
      imageMobile: '/img/landing/notebook/notebook-mobile.png',
    },
    {
      id: 4,
      title: 'Продвижение в Рунете',
      text:
        'SEO продвижение, контекстная реклама в YANDEX и GOOGLE. Медиа.\n' +
        'Мы продвигаем - вы получаете заявки и звонки от клиентов!\n',
      image: '/img/landing/notebook/notebook.png',
      imageMobile: '/img/landing/notebook/notebook-mobile.png',
    },
    {
      id: 5,
      title: 'Рекламное продвижение',
      text:
        'Выделите свое объявление среди сотен других, подключите премиум размещение на DЕЛИSPACE. ' +
        'Ваша площадка будет в топе выдачи на нашем маркетплейсе. \n' +
        'А если готовы обрабатывать шквал обращений от клиентов - выбирайте баннер!\n',
      image: '/img/landing/notebook/notebook.png',
      imageMobile: '/img/landing/notebook/notebook-mobile.png',
    },
  ]

  public created(): void {
    this.change(this.activePoint)
  }

  change(id: number): void {
    this.activePoint = id
    const active = this.list.find((item) => item.id === this.activePoint)

    if (active) {
      this.activeObj = active
    }
  }
}
